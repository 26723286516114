import React, { useEffect } from "react";
import Slider from "react-slick";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "./slider.css";
import Checkbox from "../Checkbox/Checkbox";

export default function WineSlider({ wines, selectedWines, setSelectedWines }) {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
    };

    // default arrows of this Slider library are ugly and that's why I hid them with CSS
    // this effect is for replace them for my own arrows
    useEffect(() => {
        setTimeout(() => {
            const uglyPrevBtn = document.querySelector(".slick-prev");
            const uglyNextBtn = document.querySelector(".slick-next");
            const myPrevBtn = document.querySelector("#prev");
            const myNextBtn = document.querySelector("#next");

            myPrevBtn.addEventListener("click", () => uglyPrevBtn.click());
            myNextBtn.addEventListener("click", () => uglyNextBtn.click());
        }, 500);
    }, []);

    return (
        <div
            style={{
                maxWidth: "900px",
                position: "relative",
                marginBottom: "32px",
            }}
        >
            <Slider {...settings}>
                {wines.map(({ id, name, photo }) => {
                    return (
                        <div key={id} className="wine-card" id={"card-" + id}>
                            <div className="wine-card__header">
                                <img src={photo} alt={name} />
                            </div>
                            <div className="wine-card__body">
                                <Checkbox
                                    id={id}
                                    name="wines"
                                    selectedWines={selectedWines}
                                    setSelectedWines={setSelectedWines}
                                />
                                <label htmlFor={"wines-" + id}>{name}</label>
                            </div>
                        </div>
                    );
                })}
            </Slider>
            <button type="button" id="prev">
                <FaChevronLeft />
            </button>
            <button type="button" id="next">
                <FaChevronRight />
            </button>
        </div>
    );
}
