import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

export default function Header() {
    const [open, setOpen] = useState(false);

    return (
        <header>
            <nav className="container">
                <a
                    href="https://rutaluigibosca.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="logo"
                >
                    <img src="/logo-bosca.svg" alt="Luigi Bosca" />
                </a>

                <button
                    className={open ? "open" : ""}
                    id="menu-icon"
                    onClick={() => setOpen(!open)}
                >
                    <div className="bar-1"></div>
                    <div className="bar-2"></div>
                    <div className="bar-3"></div>
                </button>

                <ul className={open ? "nav-menu open" : "nav-menu"}>
                    <li>
                        <Link to="/">Cargar cupones</Link>
                    </li>
                    <li>
                        <a
                            href="https://rutaluigibosca.com/wp-content/uploads/2022/06/Bases-y-Condiciones-La-Ruta-de-Luigi-Bosca.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Bases y Condiciones
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
