import React from "react";
import { FaChevronRight } from "react-icons/fa";
import "./GNB.css";

export default function GNB({
    entry,
    setEntry,
    gnb,
    setGnb,
    next,
    handleChange,
}) {
    const setFalse = () => {
        setGnb(false);
        setEntry((prevState) => {
            return {
                ...prevState,
                gnbCard: "",
            };
        });
    };

    return (
        <>
            <label className="label" htmlFor="restaurant">
                6- ¿Pagaste con tarjeta de crédito GNB?
            </label>

            <button
                type="button"
                className={gnb ? "gnb-btn active" : "gnb-btn"}
                onClick={() => setGnb(true)}
            >
                Si
            </button>
            <button
                type="button"
                className={!gnb ? "gnb-btn active" : "gnb-btn"}
                onClick={setFalse}
            >
                No
            </button>

            {gnb ? (
                <div className="gnb-container">
                    <label className="label" htmlFor="gnb">
                        Ingresa tus últimos 4 dígitos de tu tarjeta
                    </label>
                    <input
                        type="text"
                        className="text-input"
                        id="gnbCard"
                        name="gnbCard"
                        value={entry["gnbCard"]}
                        placeholder="Escribí acá tu respuesta..."
                        onChange={handleChange}
                    />
                </div>
            ) : (
                ""
            )}

            <button type="button" className="next-btn" onClick={next}>
                Siguiente
                <FaChevronRight />
            </button>
        </>
    );
}
