import React from "react";
import axios from "axios";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import FormPage from "./pages/Form/FormPage";
import QueryPage from "./pages/Query/QueryPage";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<FormPage />} />
                <Route path="/consulta" element={<QueryPage />} />
            </Routes>
        </Router>
    );
}

export default App;
