import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Fly from "../../components/Fly/Fly";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import "./query.css";

export default function QueryPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [points, setPoints] = useState({
        points: 0,
        remainingPoints: 0,
        totalWines: 0,
    });
    const [error, setError] = useState(false);

    const govId = searchParams.get("govId");

    useEffect(() => {
        if (govId) {
            axios
                .post("contestants/find/", {
                    govId,
                })
                .then(function(response) {
                    setPoints(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError(true);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <>
            <Header />
            <section className="results">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="container">
                        {!govId || error ? (
                            <h1>
                                Lo sentimos, no hemos podido encontrar tu número
                                de documento en nuestra base de datos.
                            </h1>
                        ) : (
                            <>
                                <h1>
                                    Tenés acumulados
                                    <span className="gold">
                                        &nbsp;
                                        {points.points}&nbsp;
                                    </span>
                                    cupones.
                                </h1>
                                {points.remainingPoints === 0 ? (
                                    <h2>
                                        <span className="gold">
                                            ¡Felicidades!&nbsp;
                                        </span>
                                        Actualmente sos la persona que más
                                        cupones tiene acumulado ¡A este ritmo
                                        estás muy cerca de Mendoza!
                                    </h2>
                                ) : (
                                    <h2>
                                        Actualmente estás a
                                        <span className="gold">
                                            &nbsp;
                                            {points.remainingPoints}&nbsp;
                                        </span>
                                        cupones de la persona con más cupones
                                        acumulados.
                                    </h2>
                                )}
                                {points.totalWines < 10 ? (
                                    <h3>
                                        ¡Comprando{" "}
                                        <span className="gold">
                                            {10 - points.totalWines} vinos más
                                        </span>{" "}
                                        te asegurás un premio fijo!
                                    </h3>
                                ) : (
                                    <h3>
                                        ¡Ya tenés acumulado más de{" "}
                                        <span className="gold">
                                            10 vinos
                                        </span>! Muy pronto te vamos a contactar
                                        para coordinar la entrega de tu premio
                                    </h3>
                                )}
                                <Fly />
                            </>
                        )}
                    </div>
                )}
            </section>
        </>
    );
}
