import React from "react";
import { FaChevronRight } from "react-icons/fa";
import "./congrats.css";
import Fly from "../Fly/Fly";

export default function Congrats({ restart, govId, points }) {

    const query = () => {
        window.open(
            "/consulta/?govId=" + govId, "_blank");
    };

    return (
        <div className="congrats">
            <div>
                <h1 className="form-title gold">¡Felicitaciones!</h1>
                <h2 className="points">Conseguiste {points} puntos</h2>
                <div className="btn-group">
                    <button type="button" className="next-btn" onClick={query}>
                        Consultar mis cupones
                        <FaChevronRight />
                    </button>
                    <button className="link" onClick={restart}>
                        Cargar más cupones
                    </button>
                </div>
            </div>

            <Fly />
        </div>
    );
}
