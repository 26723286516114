import React from "react";

export default function Select({ restaurants, handleChange }) {
    return (
        <select
            className="text-input"
            id="restaurant"
            name="restaurant"
            onChange={handleChange}
            defaultValue={0}
        >
            <option value={0} disabled>
                Selecciona tu respuesta...
            </option>
            {
                restaurants.map(({ id, name}) => (
                    <option key={id} value={id}>{name}</option>
                ))
            }
        </select>
    );
}
