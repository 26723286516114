import React from "react";
import "./fly.css";

export default function Fly() {
    return (
        <div className="frame">
            <div>
                <img
                    className="travel"
                    src="/assets/travel.svg"
                    alt="Viajá a la Argentina"
                />
                <img className="plane" src="/assets/plane.svg" alt="Avión" />
            </div>
        </div>
    );
}
