import React, { useEffect, useState } from "react";
import {
    FaChevronRight,
    FaChevronLeft,
    FaCloudUploadAlt,
} from "react-icons/fa";
import Step from "../../components/Step/Step";
import "./form.css";
import Congrats from "../../components/Congrats/Congrats";
import axios from "axios";
import Select from "../../components/Select/Select";
import WineSlider from "../../components/WineSlider/WineSlider";
import Loader from "../../components/Loader/Loader";
import GNB from "../../components/GNB/GNB";

export default function FormPage() {
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [finished, setFinished] = useState(false);
    const [entry, setEntry] = useState({
        fullName: "",
        govId: "",
        email: "",
        restaurant: "",
        gnbCard: "",
    });
    const [selectedWines, setSelectedWines] = useState([]);
    const [invoice, setInvoice] = useState({
        filename: "",
        file: "",
    });
    const [error, setError] = useState("");
    const [wines, setWines] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [awardedPoints, setAwardedPoints] = useState(0);
    const [gnb, setGnb] = useState(false);

    useEffect(() => {
        // get wines list for wines slider
        axios
            .get("/wines")
            .then(function(response) {
                // handle success
                setWines(response.data);
                setLoading(false);
            })
            .catch(function(error) {
                // handle error
                console.log(error);
                setLoading(false);
            });

        // get restaurants list for restaurants select
        axios
            .get("/restaurants")
            .then(function(response) {
                // handle success
                setRestaurants(response.data);
                setLoading(false);
            })
            .catch(function(error) {
                // handle error
                console.log(error);
                setLoading(false);
            });
    }, []);

    const next = () => setStep(step + 1);
    const back = () => setStep(step - 1);
    const finish = () => {
        setFinished(true);
        handleSubmit();
    };
    const restart = () => {
        setSelectedWines([]);
        setStep(1);
        setFinished(false);
        setInvoice({
            filename: "",
            file: "",
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEntry((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSubmit = () => {
        setLoading(true);

        let data = new FormData();
        data.append("fullName", entry.fullName);
        data.append("govId", entry.govId);
        data.append("email", entry.email);
        data.append("restaurant", entry.restaurant);
        data.append("gnbCard", entry.gnbCard);
        data.append("invoice", invoice.file);
        selectedWines.forEach((wine) => data.append("wines", wine));

        axios({
            method: "post",
            url: "/entries/",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function(response) {
                //handle success
                setAwardedPoints(response.data.awardedPoints);
                setLoading(false);
            })
            .catch(function(response) {
                //handle error
                console.log(response);
                setLoading(false);
            });
    };

    return (
        <section className="form-container">
            {loading ? (
                <Loader />
            ) : (
                <div className="container">
                    {finished ? (
                        <Congrats
                            restart={restart}
                            govId={entry.govId}
                            points={awardedPoints}
                        />
                    ) : (
                        <form>
                            {step > 1 ? (
                                <button
                                    type="button"
                                    onClick={back}
                                    className="back-btn"
                                >
                                    <FaChevronLeft />
                                    Atrás
                                </button>
                            ) : (
                                ""
                            )}
                            <h1 className="form-title">
                                Completá el formulario
                            </h1>

                            <Step
                                index={1}
                                step={step}
                                label="1- Nombre completo"
                                type="text"
                                name="fullName"
                                next={next}
                                value={entry.fullName}
                                handleChange={handleChange}
                                error={error}
                                setError={setError}
                                entry={entry}
                                setEntry={setEntry}
                            />

                            <Step
                                index={2}
                                step={step}
                                label="2- Cédula de identidad"
                                type="text"
                                name="govId"
                                next={next}
                                value={entry.govId}
                                handleChange={handleChange}
                                error={error}
                                setError={setError}
                                entry={entry}
                                setEntry={setEntry}
                            />

                            <Step
                                index={3}
                                step={step}
                                label="3- Correo electrónico"
                                type="email"
                                name="email"
                                next={next}
                                value={entry.email}
                                handleChange={handleChange}
                                error={error}
                                setError={setError}
                                entry={entry}
                                setEntry={setEntry}
                            />

                            {/*--------------- START STEP 4 --------------------*/}
                            <div
                                className={step === 4 ? "step active" : "step"}
                                id="step-4"
                            >
                                <label className="label" htmlFor="restaurant">
                                    4- ¿En cuál restaurante disfrutaste tu vino?
                                </label>
                                <Select
                                    restaurants={restaurants}
                                    defaultValue={entry.restaurant}
                                    handleChange={handleChange}
                                />
                                <button
                                    type="button"
                                    className="next-btn"
                                    onClick={next}
                                >
                                    Siguiente
                                    <FaChevronRight />
                                </button>
                            </div>
                            {/*------------------ END STEP 4 ---------------------*/}

                            {/*--------------- START STEP 5 --------------------*/}
                            <div
                                className={step === 5 ? "step active" : "step"}
                                id="step-5"
                            >
                                <label className="label" htmlFor="restaurant">
                                    5- ¿Cuál vino de Luigi Bosca compraste?
                                </label>
                                <WineSlider
                                    wines={wines}
                                    setSelectedWines={setSelectedWines}
                                />
                                <button
                                    type="button"
                                    className="next-btn"
                                    onClick={next}
                                >
                                    Siguiente
                                    <FaChevronRight />
                                </button>
                            </div>
                            {/*------------------ END STEP 5 ---------------------*/}

                            {/*--------------- START STEP 6 --------------------*/}
                            <div
                                className={step === 6 ? "step active" : "step"}
                                id="step-6"
                            >
                                <GNB
                                    entry={entry}
                                    setEntry={setEntry}
                                    gnb={gnb}
                                    setGnb={setGnb}
                                    next={next}
                                    handleChange={handleChange}
                                />
                            </div>
                            {/*------------------ END STEP 6 ---------------------*/}

                            {/*--------------- START STEP 7 --------------------*/}
                            <div
                                className={step === 7 ? "step active" : "step"}
                                id="step-7"
                            >
                                <label className="label">
                                    7- Sacale una foto completa a tu factura
                                </label>
                                <label htmlFor="invoice" className="file-label">
                                    {invoice.filename.length === 0 ? (
                                        <span>Subir o tomar fotografía</span>
                                    ) : (
                                        <span>{invoice.filename}</span>
                                    )}
                                    <FaCloudUploadAlt />
                                </label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    name="invoice"
                                    id="invoice"
                                    value={entry.invoice}
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={(e) => {
                                        setInvoice({
                                            filename: e.target.files[0].name,
                                            file: e.target.files[0],
                                        });
                                    }}
                                    onClick={(e) => {
                                        e.target.value = null;
                                    }}
                                />
                                <button
                                    type="button"
                                    className="next-btn"
                                    onClick={finish}
                                >
                                    Siguiente
                                    <FaChevronRight />
                                </button>
                            </div>
                            {/*------------------ END STEP 7 ---------------------*/}
                        </form>
                    )}
                </div>
            )}
        </section>
    );
}
