import React from "react";
import { FaChevronRight } from "react-icons/fa";
import Alert from "../Alert/Alert";
import "./step.css";

export default function Step({
    index,
    step,
    label,
    type,
    name,
    next,
    handleChange,
    error,
    setError,
    entry,
    setEntry
}) {
    const validate = () => {
        if (name === "fullName") {
            if (entry[name].length < 5) {
                setError("Proporciona un nombre válido");
                return false;
            } else {
                setError("");
                next();
            }
        } else if (name === "govId") {
            const govIdRegex = /[\da-zA-Z-]/g;
            const _govId = entry[name].match(govIdRegex).join("");
            setEntry((prevState) => {
                return {
                    ...prevState,
                    [name]: _govId,
                };
            })
            next();
        } else if (name === "email") {
            if (!/^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[A-Za-z]+$/.test(entry[name])) {
                setError("Proporciona una dirección válida");
                return false;
            } else {
                setError("");
                next();
            }
        }
    };

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            validate();
        }
    };

    return (
        <div
            className={step === index ? "step active" : "step"}
            id={`step-${index}`}
        >
            <label className="label" htmlFor={name}>
                {label}
            </label>
            <input
                type={type}
                className="text-input"
                id={name}
                name={name}
                value={entry[name]}
                placeholder="Escribí acá tu respuesta..."
                onChange={handleChange}
                onKeyPress={handleKeypress}
            />
            {error.length > 0 ? (
                <Alert label={name} message={error} />
            ) : (
                ""
            )}
            <button type="button" className="next-btn" onClick={validate}>
                Siguiente
                <FaChevronRight />
            </button>
        </div>
    );
}
