import React, { useState } from "react";
import "./checkbox.css";

export default function Checkbox({
    id,
    name,
    setSelectedWines,
}) {
    const [checked, setChecked] = useState(false);

    const updateWines = (wine) => {
        if (!checked) {
            setSelectedWines((prev) => [...prev, wine]);
        } else {
            setSelectedWines((prev) => prev.filter((item) => item !== wine));
        }
    };

    const handleChange = (e) => {
        setChecked(e.target.checked);
        updateWines(e.target.value);
    };

    return (
        <>
            <input
                type="checkbox"
                id={name + "-" + id}
                name={name}
                value={id}
                onChange={handleChange}
            />
            <label htmlFor={name + "-" + id} className="checkbox">
                <div className={checked ? "checked" : ""}></div>
            </label>
        </>
    );
}
